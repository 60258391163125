import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Dashboard } from "./routes/admin/dashboard/dashboard";
import { IssuerRoute, IssuersRoute } from "./routes/admin/issuers";
import { ProductRoute, ProductsRoute } from "./routes/admin/products";
import { LicenseRoute, LicensesRoute } from "./routes/admin/licenses";
import { UserRoute, UsersRoute } from "./routes/admin/users";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { WebhookIssuedRoute } from "./routes/admin/webhooks";
import { TabularProvider } from "./components/Tabular/providers/Tabular";
import { DefaultTabularConfig } from "./constants/TabularConfig";
import { AdminLayout } from "./routes/admin/layout";
import { UserLayout } from "./routes/user/layout";
import { Login } from "./routes/auth/login";
import { UserDashboard } from "./routes/user/dashboard/dashboard";
import { Token } from "./routes/auth/token";
import { Home } from "./routes/home";
import { AuthError } from "./routes/auth/error";
import { BeneficiaryDocuments } from "./routes/user/documents/documents";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    index: true,
    element: <Home />,
  },
  {
    element: <AdminLayout />,
    path: "admin",
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        index: true,
      },
      {
        path: "issuers",
        children: [
          {
            element: <IssuersRoute />,
            index: true,
            // loader: IssuersLoader,
          },
          {
            path: ":issuerID",
            element: <IssuerRoute />,
            // loader: IssuerLoader,
          },
        ],
      },
      {
        path: "licenses",
        children: [
          {
            element: <LicensesRoute />,
            index: true,
            // loader: LicensesLoader,
          },
          {
            path: ":licenseID",
            element: <LicenseRoute />,
            // loader: LicenseLoader,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            element: <ProductsRoute />,
            index: true,
            // loader: ProductsLoader,
          },
          {
            path: ":productID",
            element: <ProductRoute />,
            // loader: ProductLoader,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            element: <UsersRoute />,
            index: true,
            // loader: UsersLoader,
          },
          {
            path: ":userID",
            element: <UserRoute />,
            // loader: UserLoader,
          },
        ],
      },
      {
        path: "webhooks",
        children: [
          {
            path: "issued",
            element: <WebhookIssuedRoute />,
          },
        ],
      },
    ],
  },
  {
    element: <UserLayout />,
    path: "beneficiary",
    children: [
      {
        path: "dashboard",
        element: <UserDashboard />,
        index: true,
      },
      {
        path: "documents",
        children: [
          {
            element: <BeneficiaryDocuments />,
            index: true,
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    children: [
      {
        path: "error",
        element: <AuthError />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "token",
        children: [
          {
            path: ":token",
            children: [
              {
                path: ":role",
                element: <Token />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

function App(): React.ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <TabularProvider config={DefaultTabularConfig}>
        <RouterProvider router={router} />
      </TabularProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
