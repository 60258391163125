import { type Users } from "../interfaces/Response";
import { ResponseFormatter } from "../utils/Response";
import type { FilterOption } from "../interfaces/FilterOption";
import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getAdminUsers = async (
  axiosInstance: AxiosInstance,
  filterOptions?: FilterOption[],
): Promise<Users[]> => {
  const params: { filter: Record<string, string> } = {
    filter: {},
  };
  filterOptions?.forEach((f) => {
    if (f.value != null) {
      params.filter[f.field] = f.value;
    }
  });

  return await axiosInstance
    .get(`${API_URL}/admin/users`, {
      params,
    })
    .then((res) => {
      return ResponseFormatter<Users[]>(res.data);
    });
};
