import React, { type ReactElement, type ReactNode } from "react";
import { Link } from "react-router-dom";

export function ButtonLink({
  to,
  children,
}: {
  to: string;
  children?: ReactNode;
}): ReactElement {
  return (
    <Link
      to={to}
      className="inline-flex rounded bg-violet-600 px-2 py-2.5 text-xs font-semibold text-white hover:bg-violet-900 hover:text-white items-center justify-center"
    >
      {children}
    </Link>
  );
}
