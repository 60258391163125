import { type License } from "../interfaces/Response";
import { ResponseFormatter } from "../utils/Response";
import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getAdminLicense = async (
  axiosInstance: AxiosInstance,
  {
    licenseID,
  }: {
    licenseID: string;
  },
): Promise<License> => {
  return await axiosInstance
    .get(`${API_URL}/admin/licenses/${licenseID}`)
    .then((res) => {
      return ResponseFormatter<License>(res.data);
    });
};
