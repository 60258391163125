import React, { type ReactElement, useEffect } from "react";
import { SignalIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";

export function Token(): ReactElement {
  const { token, role } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (token !== undefined) {
      sessionStorage.setItem("jwt", atob(token));
      navigate(`/${role}/dashboard`);
    }
  }, [token]);
  return (
    <div
      className={"flex flex-col min-h-full items-center justify-center gap-4"}
    >
      <SignalIcon className="animate-spin h-12 w-12" />
      <span>Processing...</span>
    </div>
  );
}
