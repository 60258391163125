import React, { type ReactElement, type ReactNode } from "react";

export function Container({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}): ReactElement {
  return <div className={`mx-auto max-w-7xl ${className}`}>{children}</div>;
}
