import React, { type ReactElement, type ReactNode } from "react";

export function Button({
  click,
  children,
}: {
  click: () => void;
  children?: ReactNode;
}): ReactElement {
  return (
    <div
      onClick={() => {
        click();
      }}
      className="inline-flex rounded bg-violet-600 px-2 py-2.5 text-xs font-semibold text-white hover:bg-violet-900 hover:text-white items-center justify-center cursor-pointer"
    >
      {children}
    </div>
  );
}
