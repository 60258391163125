import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getUserLicense = async (
  axiosInstance: AxiosInstance,
  {
    documentID,
  }: {
    documentID: string;
  },
): Promise<void> => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/beneficiary/documents/${documentID}`,
      {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream", // Add this to ensure we get binary data
        },
      },
    );

    // Convert response to blob if it isn't already
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    // Create download URL
    const url = window.URL.createObjectURL(blob);

    // Create invisible download link
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.download = `${documentID}.amkbl`; // or get from Content-Disposition if available

    // Trigger download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100);
  } catch (error) {
    console.error("Download failed:", error);
    throw error;
  }
};
