import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type Issuer } from "../interfaces/Response";
import { getAdminIssuer } from "../services/getAdminIssuer";
import type { AxiosInstance } from "axios";

export const useGetIssuer = (
  axiosInstance: AxiosInstance,
  {
    issuerID,
  }: {
    issuerID: string;
  },
): UseQueryResult<Issuer, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "issuer", issuerID],
    queryFn: async () => await getAdminIssuer(axiosInstance, { issuerID }),
  });
};
