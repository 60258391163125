import React from "react";
import { type Params, useParams } from "react-router-dom";
import { getAdminUser } from "../../../services/getAdminUser";
import { QueryClient } from "@tanstack/react-query";
import { useGetUser } from "../../../hooks/useGetUser";
import { useAxios } from "../../../hooks/useAxios";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: Params;
}): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "user", params.userID],
    queryFn: async () =>
      await getAdminUser(axiosInstance, {
        userID: `${params.userID}`,
      }),
  });
}

export function UserRoute(): React.ReactElement {
  const { userID } = useParams();

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: user } = useGetUser(axiosInstance, { userID: `${userID}` });

  return <div>Users Detail {user?.id}</div>;
}
