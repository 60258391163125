import { ResponseFormatter } from "../utils/Response";
import { type User } from "../interfaces/Response";
import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getAdminUser = async (
  axiosInstance: AxiosInstance,
  {
    userID,
  }: {
    userID: string;
  },
): Promise<User> => {
  return await axiosInstance
    .get(`${API_URL}/admin/users/${userID}`)
    .then((res) => {
      return ResponseFormatter<User>(res.data);
    });
};
