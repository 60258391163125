import React, { type ReactElement, type ReactNode } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export function ModelLink({
  to,
  children,
}: {
  to: string;
  children?: ReactNode;
}): ReactElement {
  return (
    <Link
      to={to}
      className="inline-flex rounded bg-gray-50 px-2 py-2.5 text-xs text-violet-500 hover:bg-violet-500 hover:text-white items-center gap-1"
    >
      {children}
      <ChevronRightIcon className={"w-3 h-3"} />
    </Link>
  );
}
