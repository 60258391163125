import React, { type ReactElement } from "react";
import Copy from "copy-to-clipboard";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

export function CopyToClipboard({ text }: { text?: string }): ReactElement {
  return text !== undefined && text.trim().length > 0 ? (
    <div
      className={
        "inline-flex gap-3 items-center bg-gray-100 rounded cursor-default"
      }
    >
      <span className={"flex font-mono pl-3"}>{text.trim()}</span>
      <a
        className={
          "bg-white m-1 text-violet-500 hover:bg-violet-500 hover:text-white p-1.5 rounded cursor-pointer"
        }
        onClick={() => {
          Copy(`${text}`, {
            format: "text/plain",
          });
        }}
      >
        <DocumentDuplicateIcon className={"w-4 h-4"} />
      </a>
    </div>
  ) : (
    <></>
  );
}
