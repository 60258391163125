import React, {
  type ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAxios } from "../../hooks/useAxios";
import { usePostAuthToken } from "../../hooks/usePostAuthToken";
import { useNavigate, useSearchParams } from "react-router-dom";

export function Login(): React.ReactElement {
  const [searchParams] = useSearchParams();

  const [submit, setSubmit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();
  const axiosInstance = useAxios();

  const { isFetched } = usePostAuthToken(axiosInstance, {
    email,
    submit: submit && !searchParams.has("pending"),
  });

  const formOnSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      event.stopPropagation();
    },
    [],
  );

  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }, []);

  const onButtonClick = useCallback((): void => {
    setSubmit(true);
  }, []);

  useEffect(() => {
    if (isFetched) {
      navigate("/auth/login?pending");
    }
  }, [isFetched]);
  return (
    <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-sm space-y-10">
        <div>
          <img
            className="mx-auto h-10 w-auto"
            src="https://abstract-markup-strapi.sfo3.digitaloceanspaces.com/6362caa3326d34b02b73a22e72da8589.svg"
            alt="Abstract Markup"
          />
          {!searchParams.has("pending") && (
            <>
              <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Abstract Markup
              </h1>
              <h2 className="mt-3 text-center text-xl font-semibold leading-5 tracking-tight text-gray-900">
                License Manager
              </h2>
            </>
          )}
          {searchParams.has("pending") && (
            <>
              <p className="mt-6 p-0 text-center text-sm text-gray-900">
                A license manager link has been sent to your inbox.
              </p>
              <p className="mt-3 p-0 text-center text-sm font-semibold text-gray-900">
                You can safely close this window.
              </p>
            </>
          )}
        </div>
        {!searchParams.has("pending") && (
          <form
            className="space-y-6"
            onSubmit={formOnSubmit}
            autoComplete={"off"}
          >
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email Address
              </label>
              <input
                name="email-address"
                type="input"
                required
                autoComplete={"off"}
                className="w-full border rounded shadow-sm p-3 text-gray-900 placeholder:text-gray-400"
                placeholder="Email Address"
                onChange={onEmailChange}
                disabled={submit}
              />
            </div>
            <div>
              <button
                type="submit"
                className={
                  "flex w-full justify-center rounded-md p-3 text-sm font-semibold leading-6 text-white bg-violet-600 hover:bg-violet-500"
                }
                disabled={submit}
                onClick={onButtonClick}
              >
                Send Link
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
