import { Dialog, Disclosure, Transition } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { type FilterOption } from "../interfaces/FilterOption";
import { type Sort } from "../interfaces/Sort";
import { Container } from "./Container";
import { FilterInput } from "./FilterInput";

export function Heading({
  title,
  rightAccessory,
  filters,
  sort,
  onFilter,
}: {
  title: string;
  rightAccessory?: React.ReactNode;
  filters?: FilterOption[];
  sort?: Sort[];
  onFilter?: (filters: FilterOption[]) => void;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const [stateFilters, setStateFilters] = useState<FilterOption[]>([]);
  const _onChange = useCallback(
    (field: string, value: string) => {
      setStateFilters((prev) => {
        const hasValue = prev.find((f) => f.field === field);
        if (hasValue === undefined) {
          return [...prev, { field, value }];
        }
        return prev.map((f) => {
          if (f.field === field) {
            return { ...f, value };
          }
          return f;
        });
      });
    },
    [filters],
  );

  useEffect(() => {
    onFilter?.(stateFilters);
  }, [stateFilters]);

  return (
    <div>
      {/* Mobile - Filters Dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white shadow-xl">
                <div className="flex items-center justify-between px-4 h-16 bg-gray-200">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white px-2 text-gray-400"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-0">
                  {filters?.map((filter) => (
                    <Disclosure
                      as="div"
                      key={filter.field}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                              <span className="font-medium text-gray-900">
                                {filter.label}
                              </span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={clsx(
                                    open ? "-rotate-180" : "rotate-0",
                                    "h-5 w-5 transform",
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {/* {section.options.map((option, optionIdx) => ( */}
                              {/*  <div */}
                              {/*    key={option.value} */}
                              {/*    className="flex items-center" */}
                              {/*  > */}
                              {/*    <input */}
                              {/*      id={`filter-mobile-${section.id}-${optionIdx}`} */}
                              {/*      name={`${section.id}[]`} */}
                              {/*      defaultValue={option.value} */}
                              {/*      type="checkbox" */}
                              {/*      className="h-4 w-4 rounded border-gray-300 text-violet-600 focus:ring-violet-500" */}
                              {/*    /> */}
                              {/*    <label */}
                              {/*      htmlFor={`filter-mobile-${section.id}-${optionIdx}`} */}
                              {/*      className="ml-3 text-sm text-gray-500" */}
                              {/*    > */}
                              {/*      {option.label} */}
                              {/*    </label> */}
                              {/*  </div> */}
                              {/* ))} */}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop - Heading */}
      <div className="bg-white border-b border-gray-200">
        <Container className={"py-12"}>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                {title}
              </h1>
            </div>
            {rightAccessory != null && (
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                {rightAccessory}
              </div>
            )}
          </div>
        </Container>

        {/* Desktop - Filters */}
        {(filters != null || sort != null) && (
          <section aria-labelledby="filter-heading">
            {/* Active filters */}
            <div className="border-t border-gray-200 bg-white py-4">
              <Container className="flex items-center gap-4">
                {filters !== null && (
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Filter
                  </h3>
                )}
                {filters?.map((option) => {
                  return (
                    <div key={option.field} className={"inline-flex"}>
                      <FilterInput
                        label={option.label}
                        field={option.field}
                        onChange={_onChange}
                      />
                    </div>
                  );
                })}
              </Container>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
