import axios, { type AxiosInstance } from "axios";
import { useNavigate } from "react-router-dom";

export const useAxios = (): AxiosInstance => {
  const navigate = useNavigate();
  const jsonWebToken = sessionStorage.getItem("jwt");
  const defaultInstance = axios.create({
    headers: {
      common: {
        Authorization: `Bearer ${jsonWebToken}`,
      },
    },
  });
  defaultInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        sessionStorage.clear();
        navigate(`/auth/login`);
      }
      return error;
    },
  );
  return defaultInstance;
};
