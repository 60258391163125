import React, { type ChangeEvent, type ReactElement, useCallback } from "react";

export function FilterInput({
  label,
  field,
  onChange,
}: {
  label?: string;
  field: string;
  onChange: (name: string, value: string) => void;
}): ReactElement {
  const _onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(field, event.target.value);
  }, []);
  return (
    <>
      <label htmlFor={field} className="hidden">
        {label}
      </label>
      <div className="relative">
        <input
          type="text"
          name={field}
          id={field}
          className="peer block w-full border rounded bg-gray-50 p-3 text-gray-900 focus:ring-0 text-sm focus:placeholder-violet-400 focus:outline-none focus:bg-violet-50 focus:text-violet-900 focus:border-violet-300"
          placeholder={label}
          onChange={_onChange}
          role="presentation"
          autoComplete="off"
        />
        <div
          className="absolute inset-x-0 rounded bottom-0 border-t border-transparent "
          aria-hidden="true"
        />
      </div>
    </>
  );
}
