import React, { type ReactElement, useContext, useMemo } from "react";
import type { DataGridColumn } from "../interfaces/DataGridColumn";
import type { DataGridRow } from "../interfaces/DataGridRow";
import { type RowCell } from "../interfaces/RowCell";
import { TabularContext } from "../providers/Tabular";

interface DefinitionListProps<ColumnKeys extends string> {
  cols: Array<DataGridColumn<ColumnKeys>>;
  rows?: Array<DataGridRow<ColumnKeys>>;
}

export function DefinitionList<ColumnKeys extends string>({
  cols,
  rows,
}: DefinitionListProps<ColumnKeys>): ReactElement {
  const config = useContext(TabularContext);
  const _cols = useMemo(() => {
    return cols.map((col) => ({
      ...col,
      label: col.label ?? <></>,
      className: col.className ?? config?.className.dl?.div?.dt?.tag,
    }));
  }, [cols]);
  return (
    <dl className={config?.className.dl?.tag}>
      {_cols.map((col) => {
        const _rows = rows?.filter((row, rowIndex) => row[col.key]);
        return (
          <div key={col.key} className={config?.className.dl?.div?.tag}>
            <dt className={col.className}>
              {col.label}
              {col.children}
            </dt>
            <dd className={config?.className.dl?.div?.dd?.tag}>
              {_rows?.map((row, rowIndex) => {
                const _row = row[col.key] as RowCell;
                return (
                  <div
                    key={`${col.key}_${rowIndex}`}
                    className={_row.className}
                  >
                    {_row.label}
                    {_row.children}
                  </div>
                );
              })}
            </dd>
          </div>
        );
      })}
    </dl>
  );
}
