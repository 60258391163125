import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type License } from "../interfaces/Response";
import { getAdminLicense } from "../services/getAdminLicense";
import type { AxiosInstance } from "axios";

export const useGetLicense = (
  axiosInstance: AxiosInstance,
  {
    licenseID,
  }: {
    licenseID: string;
  },
): UseQueryResult<License, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "license", licenseID],
    queryFn: async () => await getAdminLicense(axiosInstance, { licenseID }),
  });
};
