import React, { useCallback, useMemo, useState } from "react";
import { Heading } from "../../../components/Heading";
import { DataGrid } from "../../../components/Tabular/";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { ModelLink } from "../../../components/ModelLink";
import { ButtonLink } from "../../../components/ButtonLink";
import { Container } from "../../../components/Container";
import { Filters } from "../../../utils/Filters";
import { type FilterOption } from "../../../interfaces/FilterOption";
import { FilterResponse } from "../../../interfaces/FilterResponse";

import { useGetIssuers } from "../../../hooks/useGetIssuers";
import { TableContainer } from "../../../components/TableContainer";
import { useAxios } from "../../../hooks/useAxios";
import { QueryClient } from "@tanstack/react-query";
import { getAdminIssuers } from "../../../services/getAdminIssuers";

export async function loader(): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "issuers"],
    queryFn: async () => await getAdminIssuers(axiosInstance),
  });
}

export function IssuersRoute(): React.ReactElement {
  /**
   * Data Filters
   */
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const onFilter = useCallback((filters: FilterOption[] | undefined) => {
    const options = Filters(FilterResponse.Issuer);
    filters?.forEach((filter, index) => {
      const option = options.find((f) => f.field === filter.field);
      if (option !== undefined) {
        option.value = filter.value;
      }
    });
    setFilterOptions(
      options.filter((f) => f.value !== undefined && f.value.trim().length > 0),
    );
  }, []);

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: issuers } = useGetIssuers(axiosInstance, { filterOptions });

  /**
   * Presentation
   */
  const cols = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "description",
      label: "Description",
    },
    {
      key: "products",
      label: "Products",
    },
    {
      key: "actions",
      label: "ID",
      span: 2,
    },
  ];

  const rows = useMemo(() => {
    return issuers?.map((issuer) => ({
      name: {
        label: issuer.name,
      },
      description: {
        label: issuer.description,
      },
      products: {
        children: (
          <div className={"flex gap-4"}>
            {issuer?.products?.map((product) => {
              return (
                <ModelLink
                  key={product.id}
                  to={`/admin/products/${product.id}`}
                >
                  {product.name}
                </ModelLink>
              );
            })}
          </div>
        ),
      },
      actions: [
        {
          children: <CopyToClipboard text={issuer.id} />,
        },
        {
          children: (
            <ButtonLink to={`/admin/issuers/${issuer.id}`}>Details</ButtonLink>
          ),
        },
      ],
    }));
  }, [issuers]);

  return (
    <>
      <Heading
        title={"Issuers"}
        rightAccessory={
          <button
            type="button"
            className="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Add Issuer
          </button>
        }
        filters={Filters(FilterResponse.Issuer)}
        onFilter={onFilter}
      />
      <Container className={"pt-12"}>
        <TableContainer>
          <DataGrid cols={cols} rows={rows} />
        </TableContainer>
      </Container>
    </>
  );
}
