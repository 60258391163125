import { type Params, useParams } from "react-router-dom";
import React from "react";
import { QueryClient } from "@tanstack/react-query";
import { getAdminIssuer } from "../../../services/getAdminIssuer";
import { useGetIssuer } from "../../../hooks/useGetIssuer";
import { DataGrid, DefinitionList } from "../../../components/Tabular";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { Badge } from "../../../components/Badge";
import { ButtonLink } from "../../../components/ButtonLink";
import { Container } from "../../../components/Container";
import { Heading } from "../../../components/Heading";
import { useAxios } from "../../../hooks/useAxios";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: Params;
}): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "issuer", params.issuerID],
    queryFn: async () =>
      await getAdminIssuer(axiosInstance, {
        issuerID: `${params.issuerID}`,
      }),
  });
}

export function IssuerRoute(): React.ReactElement {
  const { issuerID } = useParams();

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: issuer } = useGetIssuer(axiosInstance, {
    issuerID: `${issuerID}`,
  });

  return issuer !== undefined ? (
    <>
      <Heading
        title={issuer.name}
        rightAccessory={
          <button
            type="button"
            className="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Edit Issuer
          </button>
        }
      />
      <Container className={"pt-12"}>
        <DefinitionList
          cols={[
            {
              key: "id",
              label: "ID",
            },
            {
              key: "name",
              label: "Name",
            },
            {
              key: "description",
              label: "Description",
            },
            {
              key: "status",
              label: "Status",
            },
            {
              key: "email",
              label: "Email",
            },
            {
              key: "products",
              label: "Products",
            },
          ]}
          rows={[
            {
              id: {
                children: <CopyToClipboard text={issuer.id} />,
              },
            },
            {
              name: {
                label: issuer.name,
              },
            },
            {
              description: {
                label: issuer.description,
              },
            },
            {
              status: {
                children: (
                  <Badge
                    label={
                      issuer?.revoked !== undefined && Boolean(issuer.revoked)
                        ? "Revoked"
                        : "Active"
                    }
                  />
                ),
              },
            },
            {
              email: {
                children: <CopyToClipboard text={issuer.email} />,
              },
            },
            {
              products: {
                children: (
                  <DataGrid
                    renderCols={true}
                    cols={[
                      {
                        key: "name",
                        label: "Name",
                        className: "w-full text-xs",
                      },
                      {
                        key: "licenses",
                        label: "Licenses",
                        span: 2,
                        className: "text-xs",
                      },
                    ]}
                    rows={issuer.products?.map((p) => {
                      return {
                        name: {
                          label: p.name,
                        },
                        licenses: [
                          {
                            // children: (
                            //   <div className={"flex gap-3"}>
                            //     <span>0</span>
                            //     <span>of</span>
                            //     {p.maximum_licenses !== undefined &&
                            //     p.maximum_licenses < 0 ? (
                            //       <Badge label={"Unlimited"} />
                            //     ) : (
                            //       <span>{`${p.maximum_licenses}`}</span>
                            //     )}
                            //   </div>
                            // ),
                          },
                          {
                            children: (
                              <ButtonLink to={`/admin/products/${p.id}`}>
                                Details
                              </ButtonLink>
                            ),
                          },
                        ],
                      };
                    })}
                  />
                ),
              },
            },
          ]}
        />
      </Container>
    </>
  ) : (
    <></>
  );
}
