import React, {
  createContext,
  type ReactElement,
  type ReactNode,
  useMemo,
} from "react";

export interface TabularConfig {
  className: {
    table?: {
      tag?: string;
      thead?: {
        tag?: string;
        tr?: {
          tag?: string;
        };
        th?: {
          tag?: string;
        };
      };
      tbody?: {
        tag?: string;
        tr?: {
          tag?: string;
        };
        td?: {
          tag?: string;
        };
      };
    };
    dl?: {
      tag?: string;
      div?: {
        tag?: string;
        dt?: {
          tag?: string;
        };
        dd?: {
          tag?: string;
        };
      };
    };
  };
}

export const TabularContext = createContext<TabularConfig | undefined>(
  undefined,
);

export const TabularProvider = ({
  config,
  children,
}: {
  config: TabularConfig;
  children: ReactNode;
}): ReactElement => {
  const value = useMemo<TabularConfig>(() => {
    return { ...config };
  }, [config]);
  return (
    <TabularContext.Provider value={value}>{children}</TabularContext.Provider>
  );
};
