import React, { type ReactElement, useMemo } from "react";
import { useAxios } from "../../../hooks/useAxios";
import { useGetUserLicenses } from "../../../hooks/useGetUserLicenses";
import { Badge } from "../../../components/Badge";
import { Button } from "../../../components/Button";
import { getUserLicense } from "../../../services/getUserLicense";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Container";
import { TableContainer } from "../../../components/TableContainer";
import { DataGrid } from "../../../components/Tabular";

export function BeneficiaryDocuments(): ReactElement {
  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data } = useGetUserLicenses(axiosInstance, {
    filterOptions: [],
  });

  const licenses = useMemo(() => {
    return data ?? [];
  }, [data]);

  const cols = [
    {
      key: "product",
      label: "Product",
      span: 2,
    },
    {
      key: "version",
      label: "Version",
    },
    {
      key: "actions",
    },
  ];

  const rows = useMemo(() => {
    return licenses.length > 0
      ? licenses?.map((license) => ({
          product: [
            {
              label: license?.product?.name,
            },
            {
              children: (
                <Badge
                  label={license?.product_version_entitlement?.name ?? ""}
                />
              ),
            },
          ],
          version: {
            children: (
              <>
                <span>{license?.product_version?.major}</span>
                <span>.</span>
                <span>{license?.product_version?.minor}</span>
                <span>.</span>
                <span>{license?.product_version?.revision}</span>
              </>
            ),
          },
          actions: {
            children: (
              <Button
                click={() => {
                  void handleDownload({
                    documentID: license?.notarized_document?.id,
                  });
                }}
              >
                Download
              </Button>
            ),
          },
        }))
      : [];
  }, [licenses]);

  // In your React component:
  const handleDownload = async ({
    documentID,
  }: {
    documentID: string;
  }): Promise<any> => {
    try {
      await getUserLicense(axiosInstance, { documentID });
    } catch (error) {
      // Handle error appropriately
      console.error("Failed to download:", error);
    }
  };

  return (
    <>
      <Heading title={"Licenses"} />
      <Container className={"pt-12"}>
        <TableContainer>
          <DataGrid cols={cols} rows={rows} />
        </TableContainer>
      </Container>
    </>
  );
}
