import React, { type ReactElement } from "react";

export function Badge({ label }: { label: string }): ReactElement {
  return label.trim().length > 0 ? (
    <span className="inline-flex items-center rounded-md bg-gray-50 px-2.5 py-2.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
      {label}
    </span>
  ) : (
    <></>
  );
}
