import { FilterResponse } from "../interfaces/FilterResponse";
import type { FilterOption } from "../interfaces/FilterOption";

export const FiltersMap: Record<FilterResponse, FilterOption[]> = {
  [FilterResponse.Issuer]: [
    {
      field: "issuerName",
      label: "Issuer Name",
    },
    {
      field: "productName",
      label: "Product Name",
    },
  ],
  [FilterResponse.License]: [
    {
      field: "issuerName",
      label: "Issuer Name",
    },
    {
      field: "productName",
      label: "Product Name",
    },
    {
      field: "userName",
      label: "User Name",
    },
  ],
  [FilterResponse.Product]: [
    {
      field: "productName",
      label: "Product Name",
    },
  ],
  [FilterResponse.User]: [
    {
      field: "userName",
      label: "User Name",
    },
  ],
};
