import React, { useCallback, useMemo, useState } from "react";
import { Heading } from "../../../components/Heading";
import { DataGrid } from "../../../components/Tabular";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { ButtonLink } from "../../../components/ButtonLink";
import { Filters } from "../../../utils/Filters";
import { Container } from "../../../components/Container";
import { FilterResponse } from "../../../interfaces/FilterResponse";
import { QueryClient } from "@tanstack/react-query";
import { getAdminUsers } from "../../../services/getAdminUsers";
import { useGetUsers } from "../../../hooks/useGetUsers";
import { type FilterOption } from "../../../interfaces/FilterOption";
import { TableContainer } from "../../../components/TableContainer";
import { useAxios } from "../../../hooks/useAxios";

export async function loader(): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "users"],
    queryFn: async () => await getAdminUsers(axiosInstance),
  });
}

export function UsersRoute(): React.ReactElement {
  /**
   * Data Filters
   */
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const onFilter = useCallback((filters: FilterOption[] | undefined) => {
    const options = Filters(FilterResponse.User);
    filters?.forEach((filter, index) => {
      const option = options.find((f) => f.field === filter.field);
      if (option !== undefined) {
        option.value = filter.value;
      }
    });
    setFilterOptions(
      options.filter((f) => f.value !== undefined && f.value.trim().length > 0),
    );
  }, []);

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: users } = useGetUsers(axiosInstance, { filterOptions });

  const cols = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "actions",
      label: "ID",
      span: 2,
    },
  ];

  const rows = useMemo(() => {
    return users?.map((user) => ({
      name: {
        label: user.name,
      },
      email: {
        label: user.email,
      },
      actions: [
        {
          children: <CopyToClipboard text={user.id} />,
        },
        {
          children: (
            <ButtonLink to={`/admin/users/${user.id}`}>Details</ButtonLink>
          ),
        },
      ],
    }));
  }, [users]);

  return (
    <>
      <Heading
        title={"Users"}
        rightAccessory={
          <button
            type="button"
            className="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Add User
          </button>
        }
        filters={Filters(FilterResponse.User)}
        onFilter={onFilter}
      />
      <Container className={"pt-12"}>
        <TableContainer>
          <DataGrid cols={cols} rows={rows} />
        </TableContainer>
      </Container>
    </>
  );
}
