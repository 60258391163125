import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type User } from "../interfaces/Response";
import { getAdminUser } from "../services/getAdminUser";
import type { AxiosInstance } from "axios";

export const useGetUser = (
  axiosInstance: AxiosInstance,
  {
    userID,
  }: {
    userID: string;
  },
): UseQueryResult<User, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "user", userID],
    queryFn: async () => await getAdminUser(axiosInstance, { userID }),
  });
};
