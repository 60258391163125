import type { TabularConfig } from "../components/Tabular/providers/Tabular";

export const DefaultTabularConfig: TabularConfig = {
  className: {
    table: {
      tag: "border border-collapse border-gray-200 divide-y min-w-full",
      thead: {
        tag: "bg-gray-50",
        tr: {
          tag: "divide-x divide-gray-200",
        },
        th: {
          tag: "py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900",
        },
      },
      tbody: {
        tag: "divide-y divide-gray-200 bg-white",
        tr: {
          tag: "divide-x divide-gray-200",
        },
        td: {
          tag: "whitespace-nowrap p-4 text-sm text-gray-500",
        },
      },
    },
    dl: {
      tag: "divide-y divide-gray-200 overflow-scroll ring-1 ring-gray-500 ring-opacity-25 rounded bg-white",
      div: {
        tag: "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 flex items-center",
        dt: {
          tag: "text-sm font-medium leading-6 text-gray-900",
        },
        dd: {
          tag: "mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0",
        },
      },
    },
  },
};
