import { ResponseFormatter } from "../utils/Response";
import { type Product } from "../interfaces/Response";
import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getAdminProduct = async (
  axiosInstance: AxiosInstance,
  {
    productID,
  }: {
    productID: string;
  },
): Promise<Product> => {
  return await axiosInstance
    .get(`${API_URL}/admin/products/${productID}`)
    .then((res) => {
      return ResponseFormatter<Product>(res.data);
    });
};
