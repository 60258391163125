import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { getAdminProduct } from "../services/getAdminProduct";
import { type Product } from "../interfaces/Response";
import type { AxiosInstance } from "axios";

export const useGetProduct = (
  axiosInstance: AxiosInstance,
  {
    productID,
  }: {
    productID: string;
  },
): UseQueryResult<Product, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "product", productID],
    queryFn: async () => await getAdminProduct(axiosInstance, { productID }),
  });
};
