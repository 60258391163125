import { Link, NavLink, Outlet } from "react-router-dom";
import React, { Fragment, useCallback, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CheckBadgeIcon,
  CubeIcon,
  DocumentPlusIcon,
  HomeIcon,
  IdentificationIcon,
  RocketLaunchIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import clsx from "clsx";

interface Nav {
  name: string;
  to: string;
  icon: any;
  subNav?: Nav[];
}

const general: Nav[] = [
  { name: "Dashboard", to: "/admin/dashboard", icon: HomeIcon },
  { name: "Licenses", to: "/admin/licenses", icon: IdentificationIcon },
  { name: "Users", to: "/admin/users", icon: UserIcon },
];

const webhooks: Nav[] = [
  { name: "Issued", to: "/admin/webhooks/issued", icon: DocumentPlusIcon },
];

const system: Nav[] = [
  { name: "Issuers", to: "/admin/issuers", icon: CheckBadgeIcon },
  { name: "Products", to: "/admin/products", icon: RocketLaunchIcon },
  { name: "Webhooks", to: "/admin/webhooks", icon: CubeIcon, subNav: webhooks },
];

export function AdminLayout(): React.ReactElement {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const GeneralNavLinks = useCallback(() => {
    return (
      <>
        <ul role="list" className="-mx-2 space-y-1">
          {general.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.to}
                className={({ isActive }) =>
                  clsx(
                    isActive
                      ? "bg-violet-100 text-violet-600"
                      : "text-gray-600 hover:text-gray-800 hover:bg-gray-100",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal",
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon
                      className={clsx(
                        isActive
                          ? "text-violet-600"
                          : "text-gray-600 group-hover:text-gray-800",
                        "h-6 w-6 shrink-0",
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </>
    );
  }, [general]);

  const SystemNavLinks = useCallback(() => {
    return (
      <>
        <div className="text-xs font-semibold leading-6 text-gray-600 uppercase">
          System
        </div>
        <ul className="-mx-2 mt-2 space-y-1">
          {system.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.to}
                className={({ isActive }) =>
                  clsx(
                    isActive
                      ? "bg-violet-100 text-violet-600"
                      : "text-gray-600 hover:text-gray-800 hover:bg-gray-100",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal",
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon
                      className={clsx(
                        isActive
                          ? "text-violet-600"
                          : "text-gray-600 group-hover:text-gray-800",
                        "h-6 w-6 shrink-0",
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </>
                )}
              </NavLink>
              {item.subNav !== undefined && (
                <ul role="list" className="my-1 space-y-1 pl-4">
                  {item.subNav?.map((subNavItem) => {
                    return (
                      <li key={subNavItem.name}>
                        <NavLink
                          to={subNavItem.to}
                          className={({ isActive }) =>
                            clsx(
                              isActive
                                ? "bg-violet-100 text-violet-600"
                                : "text-gray-600 hover:text-gray-800 hover:bg-gray-100",
                              "group flex rounded-md p-3 text-sm leading-4 font-normal pl-8",
                            )
                          }
                        >
                          {subNavItem.name}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </>
    );
  }, [system]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => {
                        setSidebarOpen(false);
                      }}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar DefinitionList.tsx, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="https://abstract-markup-strapi.sfo3.digitaloceanspaces.com/6362caa3326d34b02b73a22e72da8589.svg"
                      alt="Abstract Markup"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <GeneralNavLinks />
                      </li>
                      <li>
                        <SystemNavLinks />
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar DefinitionList.tsx, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="https://abstract-markup-strapi.sfo3.digitaloceanspaces.com/6362caa3326d34b02b73a22e72da8589.svg"
              alt="Abstract Markup"
            />
            <div className={"ml-3 flex flex-col gap-0.5"}>
              <span className={"text-xs font-semibold"}>Abstract Markup</span>
              <span className={"text-xs text-gray-400"}>License Manager</span>
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <GeneralNavLinks />
              </li>
              <li>
                <SystemNavLinks />
              </li>
              <li className="-mx-6 mt-auto">
                <Link
                  to="/"
                  className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                >
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src="https://abstract-markup-strapi.sfo3.digitaloceanspaces.com/6362caa3326d34b02b73a22e72da8589.svg"
                    alt="Abstract Markup"
                  />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">Profile</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => {
            setSidebarOpen(true);
          }}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          License Manager
        </div>
        <Link to="/profile">
          <span className="sr-only">Your profile</span>
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src="https://abstract-markup-strapi.sfo3.digitaloceanspaces.com/6362caa3326d34b02b73a22e72da8589.svg"
            alt="Abstract Markup"
          />
        </Link>
      </div>

      <main className="lg:pl-72">
        <Outlet />
      </main>
    </div>
  );
}
