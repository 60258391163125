import React, { type ReactElement, useContext, useMemo } from "react";
import { type DataGridColumn } from "../interfaces/DataGridColumn";
import { type DataGridRow } from "../interfaces/DataGridRow";
import { TabularContext } from "../providers/Tabular";

interface DataGridProps<ColumnKeys extends string> {
  cols: Array<DataGridColumn<ColumnKeys>>;
  rows?: Array<DataGridRow<ColumnKeys>>;
  renderCols?: boolean;
}

export function DataGrid<ColumnKeys extends string>({
  cols,
  rows,
  renderCols = true,
}: DataGridProps<ColumnKeys>): ReactElement {
  const config = useContext(TabularContext);
  const _cols = useMemo(() => {
    return cols.map((col) => ({
      ...col,
      label: col.label ?? <></>,
      span: col.span ?? 1,
      className: col.className + " " + config?.className.table?.thead?.th?.tag,
    }));
  }, [cols]);
  return (
    <table className={config?.className.table?.tag}>
      {renderCols && (
        <thead className={config?.className.table?.thead?.tag}>
          <tr className={config?.className.table?.thead?.tr?.tag}>
            {_cols.map((col) => {
              return (
                <th
                  key={col.key}
                  scope="col"
                  className={col.className}
                  colSpan={col.span}
                >
                  {col.label}
                  {col.children}
                </th>
              );
            })}
          </tr>
        </thead>
      )}
      <tbody className={config?.className.table?.tbody?.tag}>
        {rows?.map((row, rowIndex) => (
          <tr
            key={`row_${rowIndex}`}
            className={config?.className.table?.tbody?.tr?.tag}
          >
            {cols.map((col) => {
              const cellData = row[col.key];
              const cells = Array.isArray(cellData) ? cellData : [cellData];
              return cells.map((cell, cellIndex) => (
                <td
                  key={`${col.key}_td_${rowIndex}_${cellIndex}`}
                  className={
                    cell?.className ?? config?.className.table?.tbody?.td?.tag
                  }
                  colSpan={cell?.span ?? 1}
                >
                  {cell?.label}
                  {cell?.children}
                </td>
              ));
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
