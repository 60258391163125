import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { postAuthToken } from "../services/postAuthToken";
import { type AxiosInstance } from "axios";

export const usePostAuthToken = (
  axiosInstance: AxiosInstance | null,
  {
    email,
    submit,
  }: {
    email: string;
    submit: boolean;
  },
): UseQueryResult<void, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "auth-token", email],
    queryFn: async () => {
      if (axiosInstance == null) {
        throw new Error("Axios instance is not available.");
      }
      return await postAuthToken(axiosInstance, {
        data: { beneficiary_email: email },
      });
    },
    enabled: !(axiosInstance == null) && submit,
  });
};
