import { API_URL } from "../constants/Servers";
import { type AxiosInstance } from "axios";

export const postAuthToken = async (
  axiosInstance: AxiosInstance,
  {
    data,
  }: {
    data: { beneficiary_email: string };
  },
): Promise<any> => {
  return await axiosInstance
    .post(`${API_URL}/auth/beneficiary/email`, data)
    .then((res) => {
      return res.data;
    });
};
