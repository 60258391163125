import { type Issuer } from "../interfaces/Response";
import { ResponseFormatter } from "../utils/Response";
import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getAdminIssuer = async (
  axiosInstance: AxiosInstance,
  {
    issuerID,
  }: {
    issuerID: string;
  },
): Promise<Issuer> => {
  return await axiosInstance
    .get(`${API_URL}/admin/issuers/${issuerID}`)
    .then((res) => {
      return ResponseFormatter<Issuer>(res.data);
    });
};
