import React, { type ReactElement } from "react";
import { QueryClient } from "@tanstack/react-query";
import { getUserLicenses } from "../../../services/getUserLicenses";
import { useAxios } from "../../../hooks/useAxios";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Container";
import { ButtonLink } from "../../../components/ButtonLink";

export async function loader(): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "user-licenses"],
    queryFn: async () => await getUserLicenses(axiosInstance),
  });
}

export function UserDashboard(): ReactElement {
  return (
    <>
      <Heading title={"Welcome"} />
      <Container className={"pt-12"}>
        <ButtonLink to={"/beneficiary/documents"}>Licenses</ButtonLink>
      </Container>
    </>
  );
}
