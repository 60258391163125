import React, { type ReactElement } from "react";
import { type Params, useParams } from "react-router-dom";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Container";
import { QueryClient } from "@tanstack/react-query";
import { getAdminProduct } from "../../../services/getAdminProduct";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { useAxios } from "../../../hooks/useAxios";
import { DefinitionList } from "../../../components/Tabular";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
// import { Badge } from "../../../components/Badge";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: Params;
}): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "product", params.productID],
    queryFn: async () =>
      await getAdminProduct(axiosInstance, {
        productID: `${params.productID}`,
      }),
  });
}

export function ProductRoute(): ReactElement {
  const { productID } = useParams();

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: product } = useGetProduct(axiosInstance, {
    productID: `${productID}`,
  });

  if (product === undefined) {
    return <></>;
  }

  return (
    <>
      <Heading
        title={product.name}
        rightAccessory={
          <button
            type="button"
            className="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Edit Product
          </button>
        }
      />
      <Container className={"pt-12"}>
        <DefinitionList
          cols={[
            {
              key: "id",
              label: "ID",
            },
            {
              key: "name",
              label: "Name",
            },
            {
              key: "description",
              label: "Description",
            },
            {
              key: "productKeys",
              label: "Product Keys",
            },
          ]}
          rows={[
            {
              id: {
                children: <CopyToClipboard text={product.id} />,
              },
            },
            {
              name: {
                label: product.name,
              },
            },
            {
              description: {
                label: product.name,
              },
            },
            {
              productKeys: {
                children: (
                  <div className={"flex flex-col gap-6"}>
                    {/* {product["product-keys"]?.map((productKey) => { */}
                    {/*  return ( */}
                    {/*    <DefinitionList */}
                    {/*      key={productKey.id} */}
                    {/*      cols={[ */}
                    {/*        { */}
                    {/*          key: "id", */}
                    {/*          label: "ID", */}
                    {/*        }, */}
                    {/*        { */}
                    {/*          key: "version", */}
                    {/*          label: "Version", */}
                    {/*        }, */}
                    {/*      ]} */}
                    {/*      rows={[ */}
                    {/*        { */}
                    {/*          id: { */}
                    {/*            children: ( */}
                    {/*              <CopyToClipboard text={productKey.id} /> */}
                    {/*            ), */}
                    {/*          }, */}
                    {/*        }, */}
                    {/*        { */}
                    {/*          version: { */}
                    {/*            children: ( */}
                    {/*              <Badge */}
                    {/*                label={`${productKey.version_major ?? 0}.${productKey.version_minor ?? 0}.${productKey.version_revision ?? 0}`} */}
                    {/*              /> */}
                    {/*            ), */}
                    {/*          }, */}
                    {/*        }, */}
                    {/*      ]} */}
                    {/*    /> */}
                    {/*  ); */}
                    {/* })} */}
                  </div>
                ),
              },
            },
          ]}
        />
      </Container>
    </>
  );
}
