import React, { type ReactElement } from "react";
import { Link } from "react-router-dom";

export function Home(): ReactElement {
  return (
    <main className={"h-screen flex items-center justify-center"}>
      <div className={"flex flex-col gap-6"}>
        <Link to={"/auth/login"}>
          <img
            className="h-10 w-auto"
            src="https://abstract-markup-strapi.sfo3.digitaloceanspaces.com/6362caa3326d34b02b73a22e72da8589.svg"
            alt="Abstract Markup"
          />
        </Link>
      </div>
    </main>
  );
}
