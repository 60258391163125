import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type FilterOption } from "../interfaces/FilterOption";
import { type Users } from "../interfaces/Response";
import { getAdminUsers } from "../services/getAdminUsers";
import type { AxiosInstance } from "axios";

export const useGetUsers = (
  axiosInstance: AxiosInstance,
  {
    filterOptions,
  }: {
    filterOptions: FilterOption[];
  },
): UseQueryResult<Users[], DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "users", filterOptions],
    queryFn: async () => await getAdminUsers(axiosInstance, filterOptions),
  });
};
